import _graphTypes from "./graphTypes";
import _nodeMap from "./nodeMap";
var exports = {};
const {
  isSubjectReference: _isSubjectReference
} = _graphTypes;
const {
  createMergedNodeMap: _createMergedNodeMap
} = _nodeMap;
const api = {};
exports = api;

/**
 * Performs JSON-LD flattening.
 *
 * @param input the expanded JSON-LD to flatten.
 *
 * @return the flattened output.
 */
api.flatten = input => {
  const defaultGraph = _createMergedNodeMap(input);

  // produce flattened output
  const flattened = [];
  const keys = Object.keys(defaultGraph).sort();
  for (let ki = 0; ki < keys.length; ++ki) {
    const node = defaultGraph[keys[ki]];
    // only add full subjects to top-level
    if (!_isSubjectReference(node)) {
      flattened.push(node);
    }
  }
  return flattened;
};
export default exports;