var exports = {};
exports = jsonld => {
  class JsonLdProcessor {
    toString() {
      return "[object JsonLdProcessor]";
    }
  }
  Object.defineProperty(JsonLdProcessor, "prototype", {
    writable: false,
    enumerable: false
  });
  Object.defineProperty(JsonLdProcessor.prototype, "constructor", {
    writable: true,
    enumerable: false,
    configurable: true,
    value: JsonLdProcessor
  });

  // The Web IDL test harness will check the number of parameters defined in
  // the functions below. The number of parameters must exactly match the
  // required (non-optional) parameters of the JsonLdProcessor interface as
  // defined here:
  // https://www.w3.org/TR/json-ld-api/#the-jsonldprocessor-interface

  JsonLdProcessor.compact = function (input, ctx) {
    if (arguments.length < 2) {
      return Promise.reject(new TypeError("Could not compact, too few arguments."));
    }
    return jsonld.compact(input, ctx);
  };
  JsonLdProcessor.expand = function (input) {
    if (arguments.length < 1) {
      return Promise.reject(new TypeError("Could not expand, too few arguments."));
    }
    return jsonld.expand(input);
  };
  JsonLdProcessor.flatten = function (input) {
    if (arguments.length < 1) {
      return Promise.reject(new TypeError("Could not flatten, too few arguments."));
    }
    return jsonld.flatten(input);
  };
  return JsonLdProcessor;
};
export default exports;